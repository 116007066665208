import { Campaign, File } from '@/gql'
import { gql } from '@apollo/client'

export default gql`
  fragment GetCampaignFragment on Campaign {
    id
    name
    companyName
    logo
    startDate
    endDate
    details
    questionnaire
    referralQuestionnaire
    senderAddress
    referralCampaign
    reuseSameIncentives
    typeOfCampaign
    isDraft
    postcardTemplate
    emailTemplate
    happinessCheck
    salutation
    completedStep
    printFilesLink
    printPassword
    updatedAt
    mainLandingText
    referralLandingText
    campaignCategory
    campaignIncentives {
      incentiveItem {
        id
        skuCode
        picture
      }
      referral
      isVisible
    }
    privacyLink
    emailAddress
    customerId
    customer {
      id
      name
      integrations {
        name
        type
        features
      }
    }
    scheduleDateTime
    optionalSettings
    files {
      id
      s3Key
      url
      aggregateId
      fileType
      fileVariant
      createdAt
      updatedAt
    }
    ecFlowTexts
    languages
  }
`

export type GetCampaignFragment = Pick<
  Campaign,
  | 'id'
  | 'name'
  | 'companyName'
  | 'logo'
  | 'startDate'
  | 'endDate'
  | 'details'
  | 'questionnaire'
  | 'referralQuestionnaire'
  | 'senderAddress'
  | 'referralCampaign'
  | 'reuseSameIncentives'
  | 'typeOfCampaign'
  | 'isDraft'
  | 'postcardTemplate'
  | 'emailTemplate'
  | 'happinessCheck'
  | 'salutation'
  | 'mainLandingText'
  | 'referralLandingText'
  | 'completedStep'
  | 'printFilesLink'
  | 'printPassword'
  | 'campaignIncentives'
  | 'updatedAt'
  | 'campaignCategory'
  | 'emailAddress'
  | 'customer'
  | 'optionalSettings'
  | 'customerId'
  | 'ecFlowTexts'
  | 'languages'
  | 'privacyLink'
> & { files: File[] }
